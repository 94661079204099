import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, FormSpy } from 'react-final-form';

import { getExpensesCollectionRefs, getIsUserDealTeamOwner } from 'selectors';

import { Asterisk, Hr } from 'components/shared';
import { Condition, FormFlex, FormBox, FormGroupAdapter, FormLegend } from 'components/shared/form';
import { Label, Textarea } from 'components/shared/formControls';

import { CREDIT_CARD_AUTHORIZATION_TERM } from 'constants/forms';
import {
  composeValidators,
  monthCollection,
  parseCardNumber,
  upperLettersOnlyParse,
  validateAccepted,
  validateCardCVV,
  validateCardExpiryMonth,
  validateCardExpiryYear,
  validateCardNumber,
  validateEmail,
  validatePhone,
  validateRequired,
  yearsRangeCollection,
  getIn,
} from 'utils';

import { isProd } from 'config';

const propTypes = {
  refs: PropTypes.shape({
    countriesCollection: PropTypes.arrayOf(PropTypes.object),
    statesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
  isUserDealTeamOwner: PropTypes.bool,
};

const defaultProps = {
  refs: {
    statesCollection: [],
    countriesCollection: [],
  },
  isUserDealTeamOwner: false,
};

const yearsCollection = yearsRangeCollection(10);

function Fields({ refs, isUserDealTeamOwner, isAllPropertiesMultiFamily }) {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="appraisalPayment.firstName"
            label="First Name"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="appraisalPayment.lastName"
            label="Last Name"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="appraisalPayment.email"
            label="Email"
            required
            validate={composeValidators(validateRequired(), validateEmail())}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="appraisalPayment.phone"
            label="Phone"
            required
            validate={composeValidators(validateRequired(), validatePhone())}
            typeOfMask="phone"
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledLegend>Billing Address</StyledLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="appraisalPayment.billingStreetAddress"
            label="Street Address"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="appraisalPayment.billingCountryCode"
            label="Country"
            required
            type="select"
            options={refs.countriesCollection}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="appraisalPayment.billingCity"
            label="City"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>

        <Condition reverse when="appraisalPayment.billingCountryCode" is="US">
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              isConditional
              name="appraisalPayment.billingProvince"
              label="State/Province"
              component={FormGroupAdapter}
            />
          </FormBox>

          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="appraisalPayment.billingPostalCode"
              label="Postal Code"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>

        <Condition when="appraisalPayment.billingCountryCode" is="US">
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              isConditional
              name="appraisalPayment.billingStateCode"
              label="State"
              type="select"
              required
              validate={validateRequired()}
              options={refs.statesCollection}
              component={FormGroupAdapter}
            />
          </FormBox>

          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="appraisalPayment.billingPostalCode"
              label="ZIP Code"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledLegend>Credit Card</StyledLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="appraisalPayment.creditCard.number"
            label="Card Number"
            placeholder="•••• •••• •••• ••••"
            parse={parseCardNumber}
            required
            validate={composeValidators(validateCardNumber(), validateRequired())}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="appraisalPayment.creditCard.verificationValue"
            label="CVV"
            typeOfMask="cvv"
            placeholder="•••"
            required
            validate={composeValidators(
              validateCardCVV({ cardNumberPath: 'appraisalPayment.creditCard.number' }),
              validateRequired(),
            )}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="appraisalPayment.creditCard.firstName"
            label="First Name"
            parse={upperLettersOnlyParse}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="appraisalPayment.creditCard.lastName"
            label="Last Name"
            required
            parse={upperLettersOnlyParse}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledLabel htmlFor="appraisalPayment.creditCard.month">
            <StyledAsterisk />
            Expiration Date
          </StyledLabel>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="appraisalPayment.creditCard.month"
            required
            validate={composeValidators(
              validateCardExpiryMonth({ yearPath: 'appraisalPayment.creditCard.year' }),
              validateRequired(),
            )}
            type="select"
            options={monthCollection}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="appraisalPayment.creditCard.year"
            required
            validate={composeValidators(
              validateCardExpiryYear({ monthPath: 'appraisalPayment.creditCard.month' }),
              validateRequired(),
            )}
            type="select"
            options={yearsCollection}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Hr />
        </FormBox>
      </FormFlex>

      <Field
        name="appraisalPayment.rushAmount"
        render={({ input: { value } }) =>
          value && (
            <FormFlex>
              <FormBox width={1}>
                <Field
                  name="appraisalPayment.rush"
                  type="checkbox"
                  label={`Appraisal Rush ($${value})`}
                  inlineGroup
                  labelOrder={1}
                  errorOrder={2}
                  component={FormGroupAdapter}
                />
                <StyledAppraisalPaymentDescription>
                  Rush is subject to appraiser availability and lender approval. Please allow 24 hours for confirmation.
                </StyledAppraisalPaymentDescription>
              </FormBox>
            </FormFlex>
          )
        }
      />

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="appraisalPayment.otherItemsAmount"
            typeOfMask="currency"
            label="Other Items"
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <Condition when="appraisalPayment.accessContactRequired" is="true">
        <FormFlex>
          <FormBox mb="1rem" width={1}>
            <FormLegend>Access Contact</FormLegend>
            <StyledLegendHint>This person will allow the appraiser to enter the property.</StyledLegendHint>
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              component={FormGroupAdapter}
              label="First Name"
              name="appraisalPayment.accessContact.firstName"
              required
              validate={validateRequired()}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              component={FormGroupAdapter}
              label="Last Name"
              name="appraisalPayment.accessContact.lastName"
              required
              validate={validateRequired()}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              component={FormGroupAdapter}
              label="Legal Name"
              name="appraisalPayment.accessContact.legalName"
              // validate={validateRequired()}
            />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              component={FormGroupAdapter}
              label="Email"
              name="appraisalPayment.accessContact.email"
              required
              validate={composeValidators(validateRequired(), validateEmail())}
            />
          </FormBox>

          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              component={FormGroupAdapter}
              label="Phone #"
              name="appraisalPayment.accessContact.phone"
              required
              typeOfMask="phone"
              validate={composeValidators(validateRequired(), validatePhone())}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1}>
          <StyledLegend>Credit Card Authorization Terms</StyledLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledTextarea rows={8} disabled value={CREDIT_CARD_AUTHORIZATION_TERM} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="appraisalPayment.creditCardAuthorizationTerms"
            type="checkbox"
            label="Agree"
            inlineGroup
            labelOrder={1}
            errorOrder={2}
            required
            validate={validateAccepted()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Hr />
        </FormBox>
      </FormFlex>

      {isUserDealTeamOwner && !isAllPropertiesMultiFamily && (
        <FormSpy
          subscription={{ values: true }}
          render={({ values }) => {
            const notifyBorrowerRequired = getIn('appraisalPayment.notifyBorrowerRequired', values);
            if (notifyBorrowerRequired) {
              return (
                <FormFlex>
                  <FormBox width={1}>
                    <Field
                      name="appraisalPayment.notifyBorrower"
                      type="checkbox"
                      label="Send Payment Confirmation Email to Borrower"
                      inlineGroup
                      labelOrder={1}
                      errorOrder={2}
                      component={FormGroupAdapter}
                    />
                  </FormBox>
                </FormFlex>
              );
            }
            return null;
          }}
        />
      )}
    </Fragment>
  );
}

const StyledLegend = styled(FormLegend)`
  margin-bottom: 1rem;
`;

const StyledLegendHint = styled(FormLegend)`
  font-size: 0.85rem;
  font-weight: bold;
`;

const StyledLabel = styled(Label)`
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: 0 0 3px 3px;
  font-size: 0.9375rem;
  color: #6d6b69;
`;

const StyledAsterisk = styled(Asterisk)`
  position: absolute;
  top: 0;
  left: -10px;
  font-size: 65%;
`;

const StyledTextarea = styled(Textarea)`
  margin-bottom: 0.5rem;
`;

const StyledAppraisalPaymentDescription = styled(FormLegend)`
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
  font-weight: bold;
  color: #d94045;
`;

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getExpensesCollectionRefs(state),
    isUserDealTeamOwner: getIsUserDealTeamOwner(state),
  };
}

const enhance = compose(withRouter, connect(mapStateToProps), pure);

export default enhance(Fields);
