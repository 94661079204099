import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, useField } from 'react-final-form';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';
import { getBridgeFullFormSecondStepPropertiesRefs, getConstructionBudgetDocumentCategory } from 'selectors';

import {
  AddressFormGroupAdapter,
  FormBox,
  FormFlex,
  FormGeocoderProvider,
  FormGroup,
  FormGroupAdapter,
  RadioGroupAdapter,
} from 'components/shared/form';
import Condition from 'components/shared/form/ConditionNew';
import { Radio } from 'components/shared/formControls';
import { DocCategory } from 'components/blocks';
import { AddressFields } from 'components/Application/StepsPage/shared';
import { utils } from 'components/shared';

import { validateRequired } from 'utils';

const propTypes = {
  constructionBudgetDocumentCategory: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  meta: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string.isRequired,
  isEditAddress: PropTypes.bool,
  refs: PropTypes.shape({
    actualRentalTypesCollection: PropTypes.arrayOf(PropTypes.object),
    occupanciesCollection: PropTypes.arrayOf(PropTypes.object),
    exitStrategiesCollection: PropTypes.arrayOf(PropTypes.object),
    constructionBudgetsCollection: PropTypes.arrayOf(PropTypes.object),
    systemTypesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  constructionBudgetDocumentCategory: undefined,
  meta: {},
  isEditAddress: false,
  refs: {
    actualRentalTypesCollection: [],
    occupanciesCollection: [],
    exitStrategiesCollection: [],
    constructionBudgetsCollection: [],
    systemTypesCollection: [],
  },
};

function BridgeArrayFields({
  constructionBudgetDocumentCategory,
  fields,
  index,
  isEditAddress,
  isFormDisabled,
  meta,
  name,
  refs,
}) {
  const propertyTypeField = useField(`${name}.type`);

  return (
    <Fragment>
      <FormGeocoderProvider namespace={name}>
        <FormFlex>
          <FormBox width={1}>
            <Field
              name={`${name}.address`}
              nameSpace={name}
              required
              isDisabled={isFormDisabled || !isEditAddress}
              disabled={isFormDisabled || !isEditAddress}
              label="Address"
              validate={validateRequired()}
              component={AddressFormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <AddressFields />
      </FormGeocoderProvider>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.type`}
            label="Property Type"
            type="select"
            options={refs.actualRentalTypesCollection}
            required
            isDisabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.occupancy`}
            label="Is the Property Leased?"
            type="select"
            options={refs.occupanciesCollection}
            required
            isDisabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>

        <Condition
          when={`${name}.type`}
          is={['land']}
          names={[`${name}.unitsCount`]}
          reverse
        >
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.unitsCount`}
              label="# of Units"
              typeOfMask="number"
              required={utils.isUnitsCountRequired(propertyTypeField.input.value)}
              disabled={isFormDisabled}
              validate={(value, allValues) => utils.unitsCountValidate(value, allValues, index)}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>
      </FormFlex>

      <Condition
        when="application.transactionPurpose"
        is={['purchase']}
        names={[`${name}.nearLocated`]}
      >
        <FormFlex>
          <FormBox width={1}>
            <Field
              component={RadioGroupAdapter}
              disabled={isFormDisabled}
              inline
              label="Is the property located within 30 minutes of your home or another investment you own/have owned?"
              name={`${name}.nearLocated`}
              type="checkbox"
            >
              <Radio value="true" id={`${name}.nearLocatedTrue`} label="Yes" />
              <Radio value="false" id={`${name}.nearLocatedFalse`} label="No" />
            </Field>
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.purchasePrice`}
            required
            label="Purchase Price"
            typeOfMask="currency"
            disabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.completedRepairsValue`}
            label="Repairs Completed"
            typeOfMask="currency"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.afterRepairValue`}
            label="Market Value After Repairs (ARV)"
            typeOfMask="currency"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.asIsValue`}
            disabled={isFormDisabled}
            label="As-Is Value"
            typeOfMask="currency"
            component={FormGroupAdapter}
            required
            validate={validateRequired()}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.totalMonthlyRent`}
            required
            label="Total Monthly Rent"
            typeOfMask="decimalCurrency"
            disabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.reTaxes`}
            disabled={isFormDisabled}
            label="RE Taxes"
            typeOfMask="decimalCurrency"
            component={FormGroupAdapter}
          />
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            name={`${name}.reTaxesPeriod`}
            type="checkbox"
          >
            <Radio value="monthly" id={`${name}.reTaxesPeriodMonthly`} label="Monthly" />
            <Radio value="annual" id={`${name}.reTaxesPeriodAnnual`} label="Annual" />
          </Field>
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.associationFee`}
            label="Association Fee"
            typeOfMask="decimalCurrency"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            name={`${name}.associationFeePeriod`}
            type="checkbox"
          >
            <Radio value="monthly" id={`${name}.associationFeePeriodMonthly`} label="Monthly" />
            <Radio value="annual" id={`${name}.associationFeePeriodAnnual`} label="Annual" />
          </Field>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.insurance`}
            label="Insurance"
            typeOfMask="decimalCurrency"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            name={`${name}.insurancePeriod`}
            type="checkbox"
          >
            <Radio value="monthly" id={`${name}.insurancePeriodMonthly`} label="Monthly" />
            <Radio value="annual" id={`${name}.insurancePeriodAnnual`} label="Annual" />
          </Field>
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.miscExpenses`}
            label="Misc. Expenses"
            typeOfMask="decimalCurrency"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            name={`${name}.miscExpensesPeriod`}
            type="checkbox"
          >
            <Radio value="monthly" id={`${name}.miscExpensesPeriodMonthly`} label="Monthly" />
            <Radio value="annual" id={`${name}.miscExpensesPeriodAnnual`} label="Annual" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition
        when="application.transactionPurpose"
        is={['refinance']}
        names={[`${name}.purchaseDate`, `${name}.existingLoanAmount`]}
      >
        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.purchaseDate`}
              label="Purchase Date"
              type="date"
              disabled={isFormDisabled}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.existingLoanAmount`}
              label="Existing Loan Amount"
              typeOfMask="currency"
              disabled={isFormDisabled}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      {constructionBudgetDocumentCategory && meta.initial.repairValue && (
        <Field name={`${fields.name}[${index}].id`} subscription={{ value: true }}>
          {({ input: { value } }) =>
            value && (
              <FormFlex>
                <FormBox width={1}>
                  <FormGroup>
                    <DocCategory
                      isDisabled={isFormDisabled}
                      targetId={value}
                      category={constructionBudgetDocumentCategory}
                    />
                  </FormGroup>
                </FormBox>
              </FormFlex>
            )
          }
        </Field>
      )}

      <Condition
        when="application.transactionPurpose"
        is={['purchase']}
        names={[`${name}.beenInside`, `${name}.accessible`]}
      >
        <FormFlex>
          <FormBox width={1}>
            <Field
              component={RadioGroupAdapter}
              disabled={isFormDisabled}
              inline
              label="Have you been inside the property?"
              name={`${name}.beenInside`}
              type="checkbox"
            >
              <Radio value="true" id={`${name}.beenInsideTrue`} label="Yes" />
              <Radio value="false" id={`${name}.beenInsideFalse`} label="No" />
            </Field>
          </FormBox>
        </FormFlex>
        <FormFlex>
          <FormBox width={1}>
            <Field
              component={RadioGroupAdapter}
              disabled={isFormDisabled}
              inline
              label="Can we obtain access to the home to conduct an appraisal or inspection?"
              name={`${name}.accessible`}
              type="checkbox"
            >
              <Radio value="true" id={`${name}.accessibleTrue`} label="Yes" />
              <Radio value="false" id={`${name}.accessibleFalse`} label="No" />
            </Field>
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Are you aware of any oil tanks, mold, or current/historical environmental issues?"
            name={`${name}.environmentalIssues`}
            type="checkbox"
          >
            <Radio value="true" id={`${name}.environmentalIssuesTrue`} label="Yes" />
            <Radio value="false" id={`${name}.environmentalIssuesFalse`} label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition
        when={`${name}.environmentalIssues`}
        is={['true']}
        names={[`${name}.environmentalIssuesDescription`]}
      >
        <FormFlex>
          <FormBox width={1}>
            <Field
              name={`${name}.environmentalIssuesDescription`}
              label="Description"
              type="textarea"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>
    </Fragment>
  );
}

BridgeArrayFields.propTypes = propTypes;
BridgeArrayFields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    constructionBudgetDocumentCategory: getConstructionBudgetDocumentCategory(state),
    refs: getBridgeFullFormSecondStepPropertiesRefs(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDocument: bindActionCreators(actions.createDocument, dispatch),
    createExplanationDocument: bindActionCreators(actions.createExplanationDocument, dispatch),
  };
}

const enhance = compose(
  withRouter,
  getContext({ isFormDisabled: PropTypes.bool, isEditAddress: PropTypes.bool }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(BridgeArrayFields);
