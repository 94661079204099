import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { prop, path } from 'ramda';

import * as actions from 'actions';
import * as requestTypes from 'constants/requestTypes';
import {
  getApplicationBorrowerScoreByContactId,
  getApplicationCurrentBorrower,
  getApplicationProperties,
  getHasApplicationProperties,
  getUserContactId,
  requestInProcess,
} from 'selectors';

import { Button, Strong, Congratulation, LoanOfficer, Paragraph } from 'components/shared';
import { FormBox, FormFlex } from 'components/shared/form';
import Well, { WellBody } from 'components/shared/Well';

import { displayDataLayerEventCompleted } from 'decorators';
import { decimalCurrencyFormat } from 'utils';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { userPropTypes, userDefaultProps } from 'propTypes/user';

import { Disclaimer, MainInfo, QuotesForBridge, QuotesForRental } from './shared';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any).isRequired,
  completeLead: PropTypes.func.isRequired,
  downloadQuotePdf: PropTypes.func.isRequired,
  downloadPreQualificationLetterPdf: PropTypes.func.isRequired,
  showQuote: PropTypes.bool,
  hasProperties: PropTypes.bool,
  properties: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  selectRequestInProgress: PropTypes.bool,
  ...userPropTypes,
};

const defaultProps = {
  showQuote: false,
  hasProperties: false,
  ...userDefaultProps,
};

const SubTitle = ({ application }) => {
  if (application.totalAmount) {
    if (application.isRental) {
      return `You have qualified for up to a ${decimalCurrencyFormat(application.totalAmount)}.`;
    }

    return `You have qualified for up to a ${decimalCurrencyFormat(application.totalAmount)} interest only loan.`;
  }
  return 'You have qualified for a loan';
};

function Qualified({
  application,
  completeLead,
  downloadQuotePdf,
  downloadPreQualificationLetterPdf,
  hasProperties,
  properties,
  quotePdfLoading,
  showQuote,
  user,
  loading,
  selectRequestInProgress,
}) {
  const address = useMemo(() => path([0, 'address'], properties), [properties]);
  const { contact } = user;
  return (
    <Well>
      <WellBody key="body">
        <ContentWrapper>
          <StyledCongratulation status="money">
            <CongratulationTitle>Congratulations!</CongratulationTitle>
            <CongratulationSubtitle>
              <SubTitle application={application} />
            </CongratulationSubtitle>
          </StyledCongratulation>
          <CongratulationButton>
            <Button style={{ marginTop: '10px' }} fz={1.125} rounded onClick={completeLead} disabled={loading || selectRequestInProgress}>
              Complete your Application
            </Button>
          </CongratulationButton>

          {!showQuote && (
            <CongratulationSubtitle>
              <NarrowParagraph>
                You have been qualified for a loan
                {hasProperties ? (
                  <Fragment>
                    {' '}
                    for <Strong>{address}</Strong>
                  </Fragment>
                ) : (
                  ''
                )}
                . Please reach out to your loan officer to receive further information.
              </NarrowParagraph>
            </CongratulationSubtitle>
          )}
        </ContentWrapper>
        {showQuote && (
          <Fragment>
            <ContentWrapper>
              <MainInfo application={application} />
            </ContentWrapper>

            {application.isRental ? (
              <QuotesForRental
                application={application}
                loading={loading}
                selectRequestInProgress={selectRequestInProgress}
              />
            ) : (
              <ContentWrapper>
                <QuotesForBridge application={application} />
              </ContentWrapper>
            )}

            {hasProperties && (
              <ButtonWrapper>
                Prequalification Letter
                <Button fz={1.125} rounded onClick={downloadPreQualificationLetterPdf}>
                  Download
                </Button>
              </ButtonWrapper>
            )}

            <ButtonWrapper>
              Quote Summary
              <Button fz={1.125} rounded onClick={downloadQuotePdf}>
                Download
              </Button>
            </ButtonWrapper>

            <CompleteButton>
              <FormFlex justifyContent="center">
                <FormBox>
                  <Button fz={1.125} rounded onClick={completeLead} disabled={loading || selectRequestInProgress}>
                    Complete your Application
                  </Button>
                </FormBox>
              </FormFlex>
            </CompleteButton>
          </Fragment>
        )}

        <StyledLoanOfficer contact={contact} application={application} />

        <ContentWrapper>
          <Disclaimer />
        </ContentWrapper>
      </WellBody>
    </Well>
  );
}

const StyledCongratulation = styled(Congratulation)`
  padding: 1.875rem 0 3.125rem;
`;

const StyledLoanOfficer = styled(LoanOfficer)`
  margin: 3.125rem 0;
`;

const CongratulationTitle = styled.div`
  font-weight: 300;
  font-size: 1.75rem;
  color: #111111;
  letter-spacing: 0.03125rem;
  margin-bottom: 0.9375rem;
`;

const CongratulationSubtitle = styled.div`
  font-weight: 300;
  font-size: 1.125rem;
  color: #666666;
  letter-spacing: 0;
  line-height: 1.8125rem;
`;

const ContentWrapper = styled.div`
  margin: auto;
  max-width: 43.125rem;
  font-size: 0.875rem;
  color: #504f4d;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 43.125rem;
  margin-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #e6e6e6;
`;

const CompleteButton = styled.div`
  display: flex;
  margin-top: 20px;
`;

const CongratulationButton = styled.div`
  margin-top: -25px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const NarrowParagraph = styled(Paragraph)`
  margin: 2rem auto 1rem;
  max-width: 28rem;
`;

Qualified.propTypes = propTypes;
Qualified.defaultProps = defaultProps;

function mapStateToProps(state) {
  const userContactId = getUserContactId(state);
  const borrowerScore = getApplicationBorrowerScoreByContactId(state, { id: userContactId });
  const quotePdfLoading = requestInProcess(state, requestTypes.QUOTE_PDF);
  const showQuote = prop('showQuote', getApplicationCurrentBorrower(state));
  const hasProperties = getHasApplicationProperties(state);
  const properties = getApplicationProperties(state);
  const loading = requestInProcess(state, requestTypes.QUOTE) ||
                  requestInProcess(state, requestTypes.DISCUSS_QUOTE) ||
                  requestInProcess(state, requestTypes.BUY_POINTS_PATCH);
  const selectRequestInProgress = requestInProcess(state, requestTypes.SELECT_QUOTE);
  return {
    userContactId,
    borrowerScore,
    quotePdfLoading,
    showQuote,
    hasProperties,
    properties,
    loading,
    selectRequestInProgress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    completeLead: bindActionCreators(actions.completeLead, dispatch),
    fetchApplicationQuote: bindActionCreators(actions.fetchApplicationQuote, dispatch),
    dataLayerPush: bindActionCreators(actions.dataLayerPush, dispatch),
    instantQuoteCompleteEvent: bindActionCreators(actions.instantQuoteCompleteEvent, dispatch),
    getQuotePdf: bindActionCreators(actions.getQuotePdf, dispatch),
    getPreQualificationLetterPdf: bindActionCreators(actions.getPreQualificationLetterPdf, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    downloadQuotePdf: ({ getQuotePdf, match }) => () => {
      const { token } = match.params;
      return getQuotePdf(token);
    },
    downloadPreQualificationLetterPdf: ({ getPreQualificationLetterPdf, match }) => () => {
      const { token } = match.params;
      return getPreQualificationLetterPdf(token);
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        application, borrowerScore, dataLayerPush,
        fetchApplicationQuote, userContactId, instantQuoteCompleteEvent,
      } = this.props;
      const { token } = application;
      fetchApplicationQuote(token);
      dataLayerPush({
        loanType: application.type,
        loanStatus: 'approved',
        userId: userContactId,
        bSc: borrowerScore,
        event: displayDataLayerEventCompleted(application),
      });
      instantQuoteCompleteEvent({ type: application.type, status: 'Approved' });
    },
  }),
  ScrollToTopOnMount,
  pure,
);

export default enhance(Qualified);
